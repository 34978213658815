import { Text } from 'src/components/primitives/Text';
import styled from 'styled-components';
import { Translation } from 'src/components/Translation';
import { ClientId } from 'src/core/ClientConfig/ClientId';
import { TermsOfUseHref } from 'src/core/ClientConfig/Splash';

export type FooterSplashScreen = {
  clientId: ClientId.Type;
  button: JSX.Element;
  termsOfUseHref: TermsOfUseHref;
};

// The plan is to unify T&C layouts but for now they have to stay as they are
export const FooterSplashScreen = ({ termsOfUseHref, button, clientId }: FooterSplashScreen) => {
  const AgeasTerms = () => (
    <TermsOfUseContainer>
      <Text as="h2" size="xl">
        <Translation id="splash.termsOfUse.heading" />
      </Text>
      <TermsOfUseTextSecondary as="p">
        <Translation id="splash.text.termsOfUse" termsOfUseHref={termsOfUseHref} />
      </TermsOfUseTextSecondary>
      <ButtonContainer>{button}</ButtonContainer>
    </TermsOfUseContainer>
  );

  const DirectAssuranceTerms = () => (
    <TermsOfUseContainer>
      <TermsOfUseTextSecondary as="p">
        <Translation id="splash.text" />
      </TermsOfUseTextSecondary>
      <ButtonContainer>{button}</ButtonContainer>
      {termsOfUseHref && (
        <TermsOfUseText as="p" size="sm">
          <Translation id="splash.text.termsOfUse" termsOfUseHref={termsOfUseHref} />
        </TermsOfUseText>
      )}
    </TermsOfUseContainer>
  );

  const DefaultTerms = () => (
    <>
      <TermsOfUseTextSecondary as="p">
        <Translation id="splash.text" />
      </TermsOfUseTextSecondary>
      <ButtonContainer>{button}</ButtonContainer>
      {termsOfUseHref && (
        <TermsOfUseText as="p" size="sm">
          <Translation id="splash.text.termsOfUse" termsOfUseHref={termsOfUseHref} />
        </TermsOfUseText>
      )}
    </>
  );

  // TODO: Create different layouts and apply it via Config
  const mapClientIdToTandC: any = {
    ageas: <AgeasTerms />,
    directassurance: <DirectAssuranceTerms />,
    dasolaris: <DirectAssuranceTerms />,
  };

  return <Footer>{mapClientIdToTandC[clientId] ? mapClientIdToTandC[clientId] : <DefaultTerms />}</Footer>;
};

const ButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin: var(--s-4) auto;
`;

const Footer = styled.div`
  text-align: center;
  padding: var(--s-8) 0 0;
`;

const TermsOfUseText = styled(Text)`
  color: var(--splash-text-color);

  a {
    text-decoration: underline;
  }
`;

const TermsOfUseTextSecondary = styled(Text)`
  color: var(--splash-text-color);
  padding-top: var(--s-1);
  a {
    color: var(--color-primary);
  }
`;

const TermsOfUseContainer = styled.div`
  text-align: left;
`;
