import React from 'react';
import styled from 'styled-components';

import { ClientConfig } from 'src/core/ClientConfig';
import { TranslationStrings } from 'src/core/Translation';
import { Header } from 'src/components/Header';
import { Page } from 'src/components/Page';

type Layout = {
  clientConfig: ClientConfig.Type;
  translationStrings: TranslationStrings;
};

export const Layout: React.FC<Layout> = ({ clientConfig, children, translationStrings }) => (
  <Page clientConfig={clientConfig} translationStrings={translationStrings}>
    <Container>
      <Header logo={clientConfig.header.logo} logoPosition={clientConfig.header.logoPosition} />
      <Main>{children}</Main>
    </Container>
  </Page>
);

const Container = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
`;

const Main = styled.main`
  display: flex;
  flex-direction: column;
  flex: 1;
  overflow: hidden;
`;
