import styled, { css } from 'styled-components';
import { FixedImage } from 'src/core/ClientConfig/Image';
import Image from 'next/image';

type Position = 'left' | 'right' | 'center';
type Header = {
  logo: FixedImage.Type;
  logoPosition: Position;
};

export const Header: React.FC<Header> = ({ logo, logoPosition }) => (
  <Container position={logoPosition}>
    <Image alt={logo.alt} src={logo.src} width={logo.width} height={logo.height} />
  </Container>
);

const Container = styled.header(
  ({ position }: { position: Position }) => css`
    display: flex;
    align-items: center;
    justify-content: ${positions[position]};
    background: var(--header-bg-color);
    border-bottom: 1px solid var(--header-border-color);
    height: var(--s-14);
    padding: 0 var(--s-4);
  `
);

const positions = {
  left: 'flex-start',
  right: 'flex-end',
  center: 'center',
};
