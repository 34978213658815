import { TranslationStrings } from 'src/core/Translation';
import { TranslationContext } from 'src/hooks';

type TranslationContextProvider = {
  translationStrings: TranslationStrings;
};

export const TranslationContextProvider: React.FC<TranslationContextProvider> = ({
  translationStrings,
  children,
}) => {
  return <TranslationContext.Provider value={translationStrings}>{children}</TranslationContext.Provider>;
};
