import React, { useEffect, useState } from 'react';
import styled, { css, keyframes } from 'styled-components';
import { match } from 'src/lib/pattern';

import { Text } from 'src/components/primitives/Text';
import { ContinueButton } from 'src/components/Buttons';
import { ButtonInvisible, ButtonPrimary } from 'src/components/primitives/Button';
import {
  Upload as UploadIcon,
  CircleCheck,
  WiFiOffline,
  CircleWarning,
} from 'src/components/primitives/Icons';
import { motion } from 'framer-motion';
import { Uploading, UploadComplete, useTranslations, useConnectionCheck } from 'src/hooks';
import { Counter, UploadingImagesState } from 'src/hooks/types';
import { useTrackEventOnce } from 'src/hooks';
import { Translation } from './Translation';

type UploadPhotosModal = {
  onUpload: () => void;
  uploadingProgress: Counter;
  uploadingImagesState: UploadingImagesState;
  onContinue: () => void;
};

const percentage = (photoUploaded: number, totalPhotos: number) => {
  // We don't start the percentage from 0 to show piece of the progressBar and give the perception
  // the upload started while initial operations are happening in the background
  return (100 * photoUploaded) / totalPhotos + 7;
};

export const UploadPhotosModal: React.FC<UploadPhotosModal> = ({
  onUpload,
  uploadingProgress,
  uploadingImagesState,
  onContinue,
}) => {
  const translations = useTranslations();
  const { isOnline } = useConnectionCheck();
  const [showOfflineContent, setShowOfflineContent] = useState(false);
  const { uploaded, total } = uploadingProgress;
  const uploadCompleted: boolean = uploadingImagesState.status.tag === UploadComplete.tag;
  const isUploading = uploadingImagesState.status.tag === Uploading.tag;
  const statusTag = uploadingImagesState.status.tag;

  const onAction = () => {
    onContinue();
  };

  const mapProgressBarTextToStatus: Record<string, string> = {
    Uploading: translations['modal.uploadPhotos.progressBar.uploading'],
    Complete: translations['modal.uploadPhotos.progressBar.uploadCompleted'],
    Failed: isOnline()
      ? translations['modal.uploadPhotos.progressBar.uploadError']
      : translations['modal.uploadPhotos.progressBar.uploadPaused'],
    Ready: translations['modal.uploadPhotos.progressBar.uploading'],
  };

  const onUploadRetry = () => {
    if (isOnline()) {
      setShowOfflineContent(false);
      onUpload();
    }
    return;
  };

  useEffect(() => {
    // Show Offline content if upload never started
    !isOnline() ? setShowOfflineContent(true) : onUpload();
  }, []);

  const FooterCardUploadSuccessful = () => {
    return (
      <ContinueButton onClick={onAction}>
        <Translation id="modal.uploadPhotos.online.cta" />
      </ContinueButton>
    );
  };

  const FooterCardUploadError = () => {
    useTrackEventOnce({ name: 'UploadPhotos - generalError' });
    return (
      <Card>
        <Text as="h1" size="xl" weight="medium">
          <StyledCircleWarning />
          <Translation id="modal.uploadPhotos.error.card.title" />
        </Text>
        <Text as="p">
          <Translation id="modal.uploadPhotos.error.card.description" />
        </Text>
        <RetryButton tag="Button" type="button" onClick={() => onUploadRetry()}>
          <Translation id="modal.uploadPhotos.offline.card.cta" />
        </RetryButton>
      </Card>
    );
  };

  const FooterCardUploadOffline = () => {
    useTrackEventOnce({ name: 'UploadPhotos - offlineError' });
    return (
      <Card>
        <Text as="h1" size="xl" weight="medium">
          <StyledWiFiOffline />
          <Translation id="modal.uploadPhotos.offline.title" />
        </Text>
        <Text as="p">
          <Translation id="modal.uploadPhotos.offline.card.description" />
        </Text>
        <RetryButton tag="Button" type="button" onClick={() => onUploadRetry()}>
          <Translation id="modal.uploadPhotos.offline.card.cta" />
        </RetryButton>
      </Card>
    );
  };

  const OfflineContent = () => {
    useTrackEventOnce({ name: 'OfflineDetected' });
    return (
      <>
        <ModalContent>
          <Text as="h1" size="xl" weight="medium" data-testid="offline-title">
            <StyledWiFiOffline />
            <Translation id="modal.uploadPhotos.offline.title" />
          </Text>
          <StyledParagraph as="p">
            <Translation id="modal.uploadPhotos.offline.description.first" />
          </StyledParagraph>
          <StyledParagraph as="p">
            <Translation id="modal.uploadPhotos.offline.description.second" />
          </StyledParagraph>
        </ModalContent>
        <ModalFooter>
          <ButtonPrimary tag="Button" type="button" onClick={() => onUploadRetry()}>
            <Translation id="modal.uploadPhotos.offline.cta" />
          </ButtonPrimary>
        </ModalFooter>
      </>
    );
  };

  const OnlineContent = () => (
    <>
      <ModalContent>
        <Text as="h1" size="xl" weight="medium" data-testid="online-title">
          {uploadCompleted ? <StyledCircleCheck /> : <StyledUploadIcon />}
          <Translation id="modal.uploadPhotos.online.title" />
        </Text>
        <ProgressBar>
          <Progress percentage={percentage(uploaded, total)} isUploading={isUploading} />
        </ProgressBar>
        <ProgressText>
          <UploadingText>
            <Text as="span">{mapProgressBarTextToStatus[statusTag]}</Text>
          </UploadingText>
          <UploadCounter>
            <Text as="span" data-testid="photos-counter">
              {' '}
              {`${uploaded.toString()}/${total.toString()} `}
              <Translation id="modal.uploadPhotos.progressBar.photos" />
            </Text>
          </UploadCounter>
        </ProgressText>
      </ModalContent>
      <ModalFooter>
        {match(uploadingImagesState.status, {
          Complete: () => <FooterCardUploadSuccessful />,
          Failed: () => (isOnline() ? <FooterCardUploadError /> : <FooterCardUploadOffline />),
          _: () => <></>,
        })}
      </ModalFooter>
    </>
  );

  return showOfflineContent ? (
    <OfflineContent key="offline-content" />
  ) : (
    <OnlineContent key="online-content" />
  );
};

const ModalContent = styled.div`
  display: flex;
  margin-top: var(--s-2);
  flex-direction: column;
`;

const ModalFooter = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: auto;
`;

const StyledUploadIcon = styled(UploadIcon)`
  display: inline-block;
  margin-right: var(--s-2);
  color: var(--color-darkGrey);
`;

const StyledCircleCheck = styled(CircleCheck)`
  display: inline-block;
  margin-right: var(--s-2);
  color: var(--color-positive);
  width: 23px;
  height: 23px;
`;

const StyledCircleWarning = styled(CircleWarning)`
  display: inline-block;
  margin-right: var(--s-2);
  color: var(--color-negative);
  width: 23px;
  height: 24px;
`;

const StyledWiFiOffline = styled(WiFiOffline)`
  display: inline-block;
  margin-right: var(--s-2);
  color: var(--color-darkGrey);
`;

const ProgressBar = styled.div`
  background: #d6d6d6;
  border-radius: 10px;
  overflow: hidden;
  margin: var(--s-4) 0 var(--s-1);
`;

const progressAnimation = keyframes`
0% {
  background-position: 0 0;
}
100% {
  background-position: 40px 40px;
}`;

const Progress = styled(motion.div)<{ percentage: number; isUploading: boolean }>`
  background: var(--color-tertiary);
  border-radius: 10px;
  height: 8px;
  width: 0px;
  transition: 0.5s ease;
  position: relative;
  overflow: hidden;
  &:before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      background-image: linear-gradient(
        -45deg,
        rgba(255, 255, 255, 0.2) 25%,
        transparent 25%,
        transparent 50%,
        rgba(255, 255, 255, 0.2) 50%,
        rgba(255, 255, 255, 0.2) 75%,
        transparent 75%,
        transparent
      );
      z-index: 1;
      background-size: 40px 40px;
      animation: ${progressAnimation} 2s linear infinite;
      overflow: hidden;
    }

    ${({ percentage, isUploading }) =>
      (percentage === 105 || !isUploading) &&
      css`
        &:before {
          background: none;
          animation: none;
        }
      `}
  }
  ${({ percentage }) =>
    css`
      width: ${percentage}%;
    `}
`;

const UploadingText = styled.span`
  display: flex;
`;

const UploadCounter = styled.span`
  display: flex;
`;

const ProgressText = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

const Card = styled.div`
  border: 1px solid var(--color-veryLightGrey);
  box-sizing: border-box;
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.25);
  border-radius: 4px;
  padding: var(--s-4);
  display: flex;
  flex-direction: column;
  margin-bottom: var(--s-2);
  width: 100%;
`;

const RetryButton = styled(ButtonInvisible)`
  display: flex;
  align-self: flex-end;
  margin-top: var(--s-4);
`;

const StyledParagraph = styled(Text)`
  margin: var(--s-1) 0;
`;
