import Head from 'next/head';
import React from 'react';
import { RootCssVars } from './styles/RootCssVars';
import { ClientConfig } from 'src/core/ClientConfig';
import { TranslationContextProvider } from './TranslationContextProvider';
import { TranslationStrings } from 'src/core/Translation';

type Page = {
  clientConfig: ClientConfig.Type;
  translationStrings: TranslationStrings;
};

export const Page: React.FC<Page> = ({ clientConfig, children, translationStrings }) => (
  <TranslationContextProvider translationStrings={translationStrings}>
    <Head>
      {!!clientConfig.theme.font.link && (
        <>
          <link rel="preconnect" href="https://fonts.gstatic.com" />
          <link href={clientConfig.theme.font.link} rel="stylesheet" />
        </>
      )}
    </Head>
    <RootCssVars clientConfig={clientConfig} />
    {children}
  </TranslationContextProvider>
);
